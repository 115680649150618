/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddWebsiteCollaboratorRequestDto } from '../models/add-website-collaborator-request-dto';
import { AuthIntegrationRequestBodyDto } from '../models/auth-integration-request-body-dto';
import { BlogPostDto } from '../models/blog-post-dto';
import { CreateParticipantDto } from '../models/create-participant-dto';
import { CreateWebsiteDto } from '../models/create-website-dto';
import { GetParticipantResponseDto } from '../models/get-participant-response-dto';
import { IntegrationProvider } from '../models/integration-provider';
import { ListWebsiteEpisodesResponseDto } from '../models/list-website-episodes-response-dto';
import { ListWebsiteIntegrationsResponseItemDto } from '../models/list-website-integrations-response-item-dto';
import { PageDto } from '../models/page-dto';
import { PodcastType } from '../models/podcast-type';
import { RestrictedBlogPostDto } from '../models/restricted-blog-post-dto';
import { RestrictedPageDto } from '../models/restricted-page-dto';
import { SendInquiryDto } from '../models/send-inquiry-dto';
import { SendInquiryResponseDto } from '../models/send-inquiry-response-dto';
import { SubscriptionPlanFeatures } from '../models/subscription-plan-features';
import { UpdateParticipantDto } from '../models/update-participant-dto';
import { UpdateWebsiteIntegrationOptionsDto } from '../models/update-website-integration-options-dto';
import { UpdateWebsiteSubdomainDto } from '../models/update-website-subdomain-dto';
import { UploadAssetDto } from '../models/upload-asset-dto';
import { UploadAudioResponseDto } from '../models/upload-audio-response-dto';
import { UploadImageResponseDto } from '../models/upload-image-response-dto';
import { ValidateWebsiteDomainResponseDto } from '../models/validate-website-domain-response-dto';
import { WebsiteBlogResponseDto } from '../models/website-blog-response-dto';
import { WebsiteInfoDto } from '../models/website-info-dto';
import { WebsiteLayoutResponseDto } from '../models/website-layout-response-dto';
import { WebsitePagesResponseDto } from '../models/website-pages-response-dto';

@Injectable({ providedIn: 'root' })
export class WebsiteApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteControllerListWebsites()` */
  static readonly WebsiteControllerListWebsitesPath = '/api/website';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerListWebsites()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListWebsites$Response(
    params?: {
      customDomain?: string;
      subdomain?: string;
      active?: boolean;
      analytics?: boolean;
      pending?: boolean;
      isCollaborator?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WebsiteInfoDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerListWebsitesPath, 'get');
    if (params) {
      rb.query('customDomain', params.customDomain, {});
      rb.query('subdomain', params.subdomain, {});
      rb.query('active', params.active, {});
      rb.query('analytics', params.analytics, {});
      rb.query('pending', params.pending, {});
      rb.query('isCollaborator', params.isCollaborator, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WebsiteInfoDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerListWebsites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListWebsites(
    params?: {
      customDomain?: string;
      subdomain?: string;
      active?: boolean;
      analytics?: boolean;
      pending?: boolean;
      isCollaborator?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<WebsiteInfoDto>> {
    return this.websiteControllerListWebsites$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WebsiteInfoDto>>): Array<WebsiteInfoDto> => r.body)
    );
  }

  /** Path part for operation `websiteControllerCreateWebsite()` */
  static readonly WebsiteControllerCreateWebsitePath = '/api/website';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerCreateWebsite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerCreateWebsite$Response(
    params: {
      body: CreateWebsiteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerCreateWebsitePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerCreateWebsite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerCreateWebsite(
    params: {
      body: CreateWebsiteDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteControllerCreateWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteControllerDeleteWebsite()` */
  static readonly WebsiteControllerDeleteWebsitePath = '/api/website/{siteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerDeleteWebsite()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteWebsite$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerDeleteWebsitePath, 'delete');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerDeleteWebsite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteWebsite(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteControllerDeleteWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteControllerUpdateSubdomain()` */
  static readonly WebsiteControllerUpdateSubdomainPath = '/api/website/{siteId}/subdomain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerUpdateSubdomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerUpdateSubdomain$Response(
    params: {
      siteId: string;
      body: UpdateWebsiteSubdomainDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerUpdateSubdomainPath, 'patch');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerUpdateSubdomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerUpdateSubdomain(
    params: {
      siteId: string;
      body: UpdateWebsiteSubdomainDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteControllerUpdateSubdomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteControllerValidateCustomDomain()` */
  static readonly WebsiteControllerValidateCustomDomainPath = '/api/website/{siteId}/validate-domain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerValidateCustomDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerValidateCustomDomain$Response(
    params: {
      siteId: string;
      domain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ValidateWebsiteDomainResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerValidateCustomDomainPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.query('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidateWebsiteDomainResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerValidateCustomDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerValidateCustomDomain(
    params: {
      siteId: string;
      domain: string;
    },
    context?: HttpContext
  ): Observable<ValidateWebsiteDomainResponseDto> {
    return this.websiteControllerValidateCustomDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValidateWebsiteDomainResponseDto>): ValidateWebsiteDomainResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerUpdateCustomDomain()` */
  static readonly WebsiteControllerUpdateCustomDomainPath = '/api/website/{siteId}/domain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerUpdateCustomDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerUpdateCustomDomain$Response(
    params: {
      siteId: string;
      domain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerUpdateCustomDomainPath, 'patch');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.query('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerUpdateCustomDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerUpdateCustomDomain(
    params: {
      siteId: string;
      domain: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteControllerUpdateCustomDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteControllerPurgeCache()` */
  static readonly WebsiteControllerPurgeCachePath = '/api/website/{siteId}/purge-cache';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerPurgeCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerPurgeCache$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerPurgeCachePath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerPurgeCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerPurgeCache(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerPurgeCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerScheduledCachePurge()` */
  static readonly WebsiteControllerScheduledCachePurgePath = '/api/website/{siteId}/scheduled-purge/{delay}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerScheduledCachePurge()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerScheduledCachePurge$Response(
    params: {
      siteId: string;
      delay: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerScheduledCachePurgePath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('delay', params.delay, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerScheduledCachePurge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerScheduledCachePurge(
    params: {
      siteId: string;
      delay: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerScheduledCachePurge$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetWebsiteLayout()` */
  static readonly WebsiteControllerGetWebsiteLayoutPath = '/api/website/layout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetWebsiteLayout()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteLayout$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteLayoutResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetWebsiteLayoutPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteLayoutResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetWebsiteLayout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteLayout(
    params?: {
    },
    context?: HttpContext
  ): Observable<WebsiteLayoutResponseDto> {
    return this.websiteControllerGetWebsiteLayout$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteLayoutResponseDto>): WebsiteLayoutResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetSitemapDetails()` */
  static readonly WebsiteControllerGetSitemapDetailsPath = '/api/website/sitemap-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetSitemapDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetSitemapDetails$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetSitemapDetailsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetSitemapDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetSitemapDetails(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerGetSitemapDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetRssPodcasts()` */
  static readonly WebsiteControllerGetRssPodcastsPath = '/api/website/rss-podcasts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetRssPodcasts()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetRssPodcasts$Response(
    params?: {
      type?: PodcastType;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetRssPodcastsPath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetRssPodcasts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetRssPodcasts(
    params?: {
      type?: PodcastType;
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.websiteControllerGetRssPodcasts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetWebsiteCustomPage()` */
  static readonly WebsiteControllerGetWebsiteCustomPagePath = '/api/website/page/{slug}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetWebsiteCustomPage()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteCustomPage$Response(
    params: {
      slug: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<(PageDto | RestrictedPageDto)>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetWebsiteCustomPagePath, 'get');
    if (params) {
      rb.path('slug', params.slug, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(PageDto | RestrictedPageDto)>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetWebsiteCustomPage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteCustomPage(
    params: {
      slug: string;
    },
    context?: HttpContext
  ): Observable<(PageDto | RestrictedPageDto)> {
    return this.websiteControllerGetWebsiteCustomPage$Response(params, context).pipe(
      map((r: StrictHttpResponse<(PageDto | RestrictedPageDto)>): (PageDto | RestrictedPageDto) => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetWebsiteBlogPost()` */
  static readonly WebsiteControllerGetWebsiteBlogPostPath = '/api/website/post/{slug}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetWebsiteBlogPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteBlogPost$Response(
    params: {
      slug: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<(BlogPostDto | RestrictedBlogPostDto)>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetWebsiteBlogPostPath, 'get');
    if (params) {
      rb.path('slug', params.slug, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(BlogPostDto | RestrictedBlogPostDto)>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetWebsiteBlogPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteBlogPost(
    params: {
      slug: string;
    },
    context?: HttpContext
  ): Observable<(BlogPostDto | RestrictedBlogPostDto)> {
    return this.websiteControllerGetWebsiteBlogPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<(BlogPostDto | RestrictedBlogPostDto)>): (BlogPostDto | RestrictedBlogPostDto) => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetWebsiteBlog()` */
  static readonly WebsiteControllerGetWebsiteBlogPath = '/api/website/blog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetWebsiteBlog()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteBlog$Response(
    params?: {
      website?: string;
      postIds?: Array<string>;
      title?: string;
      slug?: string;
      category?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      isDraft?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteBlogResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetWebsiteBlogPath, 'get');
    if (params) {
      rb.query('website', params.website, {});
      rb.query('postIds', params.postIds, {});
      rb.query('title', params.title, {});
      rb.query('slug', params.slug, {});
      rb.query('category', params.category, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('isDraft', params.isDraft, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteBlogResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetWebsiteBlog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteBlog(
    params?: {
      website?: string;
      postIds?: Array<string>;
      title?: string;
      slug?: string;
      category?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      isDraft?: boolean;
    },
    context?: HttpContext
  ): Observable<WebsiteBlogResponseDto> {
    return this.websiteControllerGetWebsiteBlog$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteBlogResponseDto>): WebsiteBlogResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetWebsitePages()` */
  static readonly WebsiteControllerGetWebsitePagesPath = '/api/website/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetWebsitePages()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsitePages$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsitePagesResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetWebsitePagesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsitePagesResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetWebsitePages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsitePages(
    params?: {
    },
    context?: HttpContext
  ): Observable<WebsitePagesResponseDto> {
    return this.websiteControllerGetWebsitePages$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsitePagesResponseDto>): WebsitePagesResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerDeleteWebsitePages()` */
  static readonly WebsiteControllerDeleteWebsitePagesPath = '/api/website/{siteId}/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerDeleteWebsitePages()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteWebsitePages$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerDeleteWebsitePagesPath, 'delete');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerDeleteWebsitePages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteWebsitePages(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteControllerDeleteWebsitePages$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteControllerDeleteWebsitePosts()` */
  static readonly WebsiteControllerDeleteWebsitePostsPath = '/api/website/{siteId}/posts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerDeleteWebsitePosts()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteWebsitePosts$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerDeleteWebsitePostsPath, 'delete');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerDeleteWebsitePosts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteWebsitePosts(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteControllerDeleteWebsitePosts$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetWebsiteInfo()` */
  static readonly WebsiteControllerGetWebsiteInfoPath = '/api/website/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetWebsiteInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteInfo$Response(
    params?: {
      id?: string;
      slug?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteInfoDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetWebsiteInfoPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('slug', params.slug, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteInfoDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetWebsiteInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetWebsiteInfo(
    params?: {
      id?: string;
      slug?: string;
    },
    context?: HttpContext
  ): Observable<WebsiteInfoDto> {
    return this.websiteControllerGetWebsiteInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteInfoDto>): WebsiteInfoDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerUploadImage()` */
  static readonly WebsiteControllerUploadImagePath = '/api/website/{siteId}/upload_image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerUploadImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  websiteControllerUploadImage$Response(
    params: {
      siteId: string;
      body: UploadAssetDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UploadImageResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerUploadImagePath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UploadImageResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerUploadImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  websiteControllerUploadImage(
    params: {
      siteId: string;
      body: UploadAssetDto
    },
    context?: HttpContext
  ): Observable<UploadImageResponseDto> {
    return this.websiteControllerUploadImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<UploadImageResponseDto>): UploadImageResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerDeleteImage()` */
  static readonly WebsiteControllerDeleteImagePath = '/api/website/{siteId}/asset/{filename}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerDeleteImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteImage$Response(
    params: {
      siteId: string;
      filename: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerDeleteImagePath, 'delete');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('filename', params.filename, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerDeleteImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteImage(
    params: {
      siteId: string;
      filename: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerDeleteImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerListSiteImages()` */
  static readonly WebsiteControllerListSiteImagesPath = '/api/website/assets/{siteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerListSiteImages()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListSiteImages$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerListSiteImagesPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerListSiteImages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListSiteImages(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.websiteControllerListSiteImages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `websiteControllerPurgeCacheForAllSites()` */
  static readonly WebsiteControllerPurgeCacheForAllSitesPath = '/api/website/purge-cahce-for-all-sites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerPurgeCacheForAllSites()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerPurgeCacheForAllSites$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerPurgeCacheForAllSitesPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerPurgeCacheForAllSites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerPurgeCacheForAllSites(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.websiteControllerPurgeCacheForAllSites$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `websiteControllerUploadAudio()` */
  static readonly WebsiteControllerUploadAudioPath = '/api/website/{siteId}/upload_audio';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerUploadAudio()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  websiteControllerUploadAudio$Response(
    params: {
      body: UploadAssetDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UploadAudioResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerUploadAudioPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UploadAudioResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerUploadAudio$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  websiteControllerUploadAudio(
    params: {
      body: UploadAssetDto
    },
    context?: HttpContext
  ): Observable<UploadAudioResponseDto> {
    return this.websiteControllerUploadAudio$Response(params, context).pipe(
      map((r: StrictHttpResponse<UploadAudioResponseDto>): UploadAudioResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerSendInquiry()` */
  static readonly WebsiteControllerSendInquiryPath = '/api/website/{siteId}/send-inquiry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerSendInquiry()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerSendInquiry$Response(
    params: {
      body: SendInquiryDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SendInquiryResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerSendInquiryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendInquiryResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerSendInquiry$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerSendInquiry(
    params: {
      body: SendInquiryDto
    },
    context?: HttpContext
  ): Observable<SendInquiryResponseDto> {
    return this.websiteControllerSendInquiry$Response(params, context).pipe(
      map((r: StrictHttpResponse<SendInquiryResponseDto>): SendInquiryResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerAuthIntegration()` */
  static readonly WebsiteControllerAuthIntegrationPath = '/api/website/{siteId}/integrations/{provider}/auth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerAuthIntegration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerAuthIntegration$Response(
    params: {
      provider: IntegrationProvider;
      siteId: string;
      body: AuthIntegrationRequestBodyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerAuthIntegrationPath, 'post');
    if (params) {
      rb.path('provider', params.provider, {});
      rb.path('siteId', params.siteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerAuthIntegration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerAuthIntegration(
    params: {
      provider: IntegrationProvider;
      siteId: string;
      body: AuthIntegrationRequestBodyDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerAuthIntegration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerRevokeIntegrationAuth()` */
  static readonly WebsiteControllerRevokeIntegrationAuthPath = '/api/website/{siteId}/integrations/{provider}/revoke-auth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerRevokeIntegrationAuth()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerRevokeIntegrationAuth$Response(
    params: {
      provider: IntegrationProvider;
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerRevokeIntegrationAuthPath, 'post');
    if (params) {
      rb.path('provider', params.provider, {});
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerRevokeIntegrationAuth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerRevokeIntegrationAuth(
    params: {
      provider: IntegrationProvider;
      siteId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerRevokeIntegrationAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetInstagramPosts()` */
  static readonly WebsiteControllerGetInstagramPostsPath = '/api/website/integrations/instagram/posts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetInstagramPosts()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetInstagramPosts$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetInstagramPostsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetInstagramPosts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetInstagramPosts(
    params?: {
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.websiteControllerGetInstagramPosts$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `websiteControllerListIntegrations()` */
  static readonly WebsiteControllerListIntegrationsPath = '/api/website/{siteId}/integrations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerListIntegrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListIntegrations$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ListWebsiteIntegrationsResponseItemDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerListIntegrationsPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ListWebsiteIntegrationsResponseItemDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerListIntegrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListIntegrations(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<Array<ListWebsiteIntegrationsResponseItemDto>> {
    return this.websiteControllerListIntegrations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ListWebsiteIntegrationsResponseItemDto>>): Array<ListWebsiteIntegrationsResponseItemDto> => r.body)
    );
  }

  /** Path part for operation `websiteControllerSetIntegrationOptions()` */
  static readonly WebsiteControllerSetIntegrationOptionsPath = '/api/website/{siteId}/integrations/{provider}/options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerSetIntegrationOptions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerSetIntegrationOptions$Response(
    params: {
      provider: IntegrationProvider;
      siteId: string;
      body: UpdateWebsiteIntegrationOptionsDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerSetIntegrationOptionsPath, 'post');
    if (params) {
      rb.path('provider', params.provider, {});
      rb.path('siteId', params.siteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerSetIntegrationOptions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerSetIntegrationOptions(
    params: {
      provider: IntegrationProvider;
      siteId: string;
      body: UpdateWebsiteIntegrationOptionsDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerSetIntegrationOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerInviteCollaborator()` */
  static readonly WebsiteControllerInviteCollaboratorPath = '/api/website/{siteId}/collaborator/invite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerInviteCollaborator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerInviteCollaborator$Response(
    params: {
      siteId: string;
      body: AddWebsiteCollaboratorRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerInviteCollaboratorPath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerInviteCollaborator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerInviteCollaborator(
    params: {
      siteId: string;
      body: AddWebsiteCollaboratorRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerInviteCollaborator$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerAcceptCollaboratorInvitation()` */
  static readonly WebsiteControllerAcceptCollaboratorInvitationPath = '/api/website/{siteId}/collaborator/accept-invite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerAcceptCollaboratorInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerAcceptCollaboratorInvitation$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerAcceptCollaboratorInvitationPath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerAcceptCollaboratorInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerAcceptCollaboratorInvitation(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerAcceptCollaboratorInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerListCollaborators()` */
  static readonly WebsiteControllerListCollaboratorsPath = '/api/website/{siteId}/collaborators';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerListCollaborators()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListCollaborators$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerListCollaboratorsPath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerListCollaborators$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListCollaborators(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.websiteControllerListCollaborators$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `websiteControllerGetSubscriptionPlanFeatures()` */
  static readonly WebsiteControllerGetSubscriptionPlanFeaturesPath = '/api/website/{siteId}/subscription-plan-features';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerGetSubscriptionPlanFeatures()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetSubscriptionPlanFeatures$Response(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SubscriptionPlanFeatures>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerGetSubscriptionPlanFeaturesPath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionPlanFeatures>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerGetSubscriptionPlanFeatures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerGetSubscriptionPlanFeatures(
    params: {
      siteId: string;
    },
    context?: HttpContext
  ): Observable<SubscriptionPlanFeatures> {
    return this.websiteControllerGetSubscriptionPlanFeatures$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubscriptionPlanFeatures>): SubscriptionPlanFeatures => r.body)
    );
  }

  /** Path part for operation `websiteControllerRemoveCollaborator()` */
  static readonly WebsiteControllerRemoveCollaboratorPath = '/api/website/{siteId}/collaborator/{collaboratorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerRemoveCollaborator()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerRemoveCollaborator$Response(
    params: {
      siteId: string;
      collaboratorId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerRemoveCollaboratorPath, 'delete');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('collaboratorId', params.collaboratorId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerRemoveCollaborator$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerRemoveCollaborator(
    params: {
      siteId: string;
      collaboratorId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerRemoveCollaborator$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerListParticipantBySlug()` */
  static readonly WebsiteControllerListParticipantBySlugPath = '/api/website/{siteId}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerListParticipantBySlug()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListParticipantBySlug$Response(
    params: {
      siteId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetParticipantResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerListParticipantBySlugPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetParticipantResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerListParticipantBySlug$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListParticipantBySlug(
    params: {
      siteId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<GetParticipantResponseDto> {
    return this.websiteControllerListParticipantBySlug$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetParticipantResponseDto>): GetParticipantResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteControllerCreateParticipant()` */
  static readonly WebsiteControllerCreateParticipantPath = '/api/website/{siteId}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerCreateParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerCreateParticipant$Response(
    params: {
      siteId: string;
      body: CreateParticipantDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerCreateParticipantPath, 'post');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerCreateParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerCreateParticipant(
    params: {
      siteId: string;
      body: CreateParticipantDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerCreateParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerDeleteParticipant()` */
  static readonly WebsiteControllerDeleteParticipantPath = '/api/website/{siteId}/participant/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerDeleteParticipant()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteParticipant$Response(
    params: {
      siteId: string;
      participantId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerDeleteParticipantPath, 'delete');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('participantId', params.participantId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerDeleteParticipant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDeleteParticipant(
    params: {
      siteId: string;
      participantId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerDeleteParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerUpdateParticipant()` */
  static readonly WebsiteControllerUpdateParticipantPath = '/api/website/{siteId}/participant/{participantId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerUpdateParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerUpdateParticipant$Response(
    params: {
      siteId: string;
      participantId: string;
      body: UpdateParticipantDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerUpdateParticipantPath, 'patch');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.path('participantId', params.participantId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerUpdateParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteControllerUpdateParticipant(
    params: {
      siteId: string;
      participantId: string;
      body: UpdateParticipantDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteControllerUpdateParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteControllerListParticipants()` */
  static readonly WebsiteControllerListParticipantsPath = '/api/website/site/{siteId}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerListParticipants()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListParticipants$Response(
    params: {
      siteId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetParticipantResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerListParticipantsPath, 'get');
    if (params) {
      rb.path('siteId', params.siteId, {});
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetParticipantResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerListParticipants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerListParticipants(
    params: {
      siteId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<Array<GetParticipantResponseDto>> {
    return this.websiteControllerListParticipants$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetParticipantResponseDto>>): Array<GetParticipantResponseDto> => r.body)
    );
  }

  /** Path part for operation `websiteControllerDomainValidation()` */
  static readonly WebsiteControllerDomainValidationPath = '/api/website/domain-validation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteControllerDomainValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDomainValidation$Response(
    params: {
      domain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsiteControllerDomainValidationPath, 'get');
    if (params) {
      rb.query('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteControllerDomainValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteControllerDomainValidation(
    params: {
      domain: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.websiteControllerDomainValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerGetWebsiteLayout()` */
  static readonly WebsitePublicControllerGetWebsiteLayoutPath = '/api/website-public/layout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerGetWebsiteLayout()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerGetWebsiteLayout$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteLayoutResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerGetWebsiteLayoutPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteLayoutResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerGetWebsiteLayout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerGetWebsiteLayout(
    params?: {
    },
    context?: HttpContext
  ): Observable<WebsiteLayoutResponseDto> {
    return this.websitePublicControllerGetWebsiteLayout$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteLayoutResponseDto>): WebsiteLayoutResponseDto => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerGetSitemapDetails()` */
  static readonly WebsitePublicControllerGetSitemapDetailsPath = '/api/website-public/sitemap-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerGetSitemapDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerGetSitemapDetails$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerGetSitemapDetailsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerGetSitemapDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerGetSitemapDetails(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websitePublicControllerGetSitemapDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerListParticipantsByWebsite()` */
  static readonly WebsitePublicControllerListParticipantsByWebsitePath = '/api/website-public/participants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerListParticipantsByWebsite()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsByWebsite$Response(
    params?: {
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetParticipantResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerListParticipantsByWebsitePath, 'get');
    if (params) {
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetParticipantResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerListParticipantsByWebsite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsByWebsite(
    params?: {
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<Array<GetParticipantResponseDto>> {
    return this.websitePublicControllerListParticipantsByWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetParticipantResponseDto>>): Array<GetParticipantResponseDto> => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerListParticipantsBySlug()` */
  static readonly WebsitePublicControllerListParticipantsBySlugPath = '/api/website-public/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerListParticipantsBySlug()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsBySlug$Response(
    params?: {
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetParticipantResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerListParticipantsBySlugPath, 'get');
    if (params) {
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetParticipantResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerListParticipantsBySlug$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsBySlug(
    params?: {
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<GetParticipantResponseDto> {
    return this.websitePublicControllerListParticipantsBySlug$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetParticipantResponseDto>): GetParticipantResponseDto => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerCreateParticipant()` */
  static readonly WebsitePublicControllerCreateParticipantPath = '/api/website-public/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerCreateParticipant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websitePublicControllerCreateParticipant$Response(
    params: {
      body: CreateParticipantDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerCreateParticipantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerCreateParticipant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websitePublicControllerCreateParticipant(
    params: {
      body: CreateParticipantDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websitePublicControllerCreateParticipant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerListParticipantsByEpisode()` */
  static readonly WebsitePublicControllerListParticipantsByEpisodePath = '/api/website-public/episode/{episodeId}/{podcastId}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerListParticipantsByEpisode()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsByEpisode$Response(
    params: {
      episodeId: string;
      podcastId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetParticipantResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerListParticipantsByEpisodePath, 'get');
    if (params) {
      rb.path('episodeId', params.episodeId, {});
      rb.path('podcastId', params.podcastId, {});
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetParticipantResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerListParticipantsByEpisode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsByEpisode(
    params: {
      episodeId: string;
      podcastId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<Array<GetParticipantResponseDto>> {
    return this.websitePublicControllerListParticipantsByEpisode$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetParticipantResponseDto>>): Array<GetParticipantResponseDto> => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerListParticipantsByPodcast()` */
  static readonly WebsitePublicControllerListParticipantsByPodcastPath = '/api/website-public/podcast/{podcastId}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerListParticipantsByPodcast()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsByPodcast$Response(
    params: {
      podcastId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetParticipantResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerListParticipantsByPodcastPath, 'get');
    if (params) {
      rb.path('podcastId', params.podcastId, {});
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetParticipantResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerListParticipantsByPodcast$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsByPodcast(
    params: {
      podcastId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<Array<GetParticipantResponseDto>> {
    return this.websitePublicControllerListParticipantsByPodcast$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetParticipantResponseDto>>): Array<GetParticipantResponseDto> => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerListParticipantsByPost()` */
  static readonly WebsitePublicControllerListParticipantsByPostPath = '/api/website-public/post/{postId}/participant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerListParticipantsByPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsByPost$Response(
    params: {
      postId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetParticipantResponseDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerListParticipantsByPostPath, 'get');
    if (params) {
      rb.path('postId', params.postId, {});
      rb.query('slug', params.slug, {});
      rb.query('status', params.status, {});
      rb.query('role', params.role, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetParticipantResponseDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerListParticipantsByPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListParticipantsByPost(
    params: {
      postId: string;
      slug?: string;
      status?: 'APPROVED' | 'PENDING' | 'DECLINED';
      role?: string;
      name?: string;
    },
    context?: HttpContext
  ): Observable<Array<GetParticipantResponseDto>> {
    return this.websitePublicControllerListParticipantsByPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetParticipantResponseDto>>): Array<GetParticipantResponseDto> => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerListEpisodes()` */
  static readonly WebsitePublicControllerListEpisodesPath = '/api/website-public/episode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerListEpisodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListEpisodes$Response(
    params?: {
      participantId?: string;
      category?: string;
      tag?: string;
      season?: string;
      title?: string;
      slug?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      year?: number;
      archived?: boolean;
      featured?: boolean;
      podcastType?: PodcastType;
      podcastId?: Array<string>;
      podcastSlug?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListWebsiteEpisodesResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerListEpisodesPath, 'get');
    if (params) {
      rb.query('participantId', params.participantId, {});
      rb.query('category', params.category, {});
      rb.query('tag', params.tag, {});
      rb.query('season', params.season, {});
      rb.query('title', params.title, {});
      rb.query('slug', params.slug, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('year', params.year, {});
      rb.query('archived', params.archived, {});
      rb.query('featured', params.featured, {});
      rb.query('podcastType', params.podcastType, {});
      rb.query('podcastId', params.podcastId, {});
      rb.query('podcastSlug', params.podcastSlug, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWebsiteEpisodesResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerListEpisodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websitePublicControllerListEpisodes(
    params?: {
      participantId?: string;
      category?: string;
      tag?: string;
      season?: string;
      title?: string;
      slug?: string;
      limit?: number;
      offset?: number;
      sortDirection?: 'asc' | 'desc';
      year?: number;
      archived?: boolean;
      featured?: boolean;
      podcastType?: PodcastType;
      podcastId?: Array<string>;
      podcastSlug?: string;
    },
    context?: HttpContext
  ): Observable<ListWebsiteEpisodesResponseDto> {
    return this.websitePublicControllerListEpisodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListWebsiteEpisodesResponseDto>): ListWebsiteEpisodesResponseDto => r.body)
    );
  }

  /** Path part for operation `websitePublicControllerUploadImage()` */
  static readonly WebsitePublicControllerUploadImagePath = '/api/website-public/upload_image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websitePublicControllerUploadImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  websitePublicControllerUploadImage$Response(
    params: {
      body: UploadAssetDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UploadImageResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsiteApi.WebsitePublicControllerUploadImagePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UploadImageResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websitePublicControllerUploadImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  websitePublicControllerUploadImage(
    params: {
      body: UploadAssetDto
    },
    context?: HttpContext
  ): Observable<UploadImageResponseDto> {
    return this.websitePublicControllerUploadImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<UploadImageResponseDto>): UploadImageResponseDto => r.body)
    );
  }

}
