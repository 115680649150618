import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  from,
  Observable,
  switchMap,
  throwError
} from 'rxjs';
import {catchError, take, filter, finalize} from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import {environment} from "../../../../environments/environment";

function isRefreshTokenUrl(url: string) {
  return url.includes('auth/refresh');
}

function isLogoutUrl(url: string) {
  return url.includes('auth/logout');
}

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private readonly isRefreshInProgressSubject = new BehaviorSubject(false);
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return (isRefreshTokenUrl(req.url) ? from(Promise.resolve(false)) : this.waitForTokenRefresh()).pipe(
      switchMap(() => next.handle(this.addTokenToRequest(req))),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handleAuthError(req, next);
        }
        return throwError(error);
      }),
    );
  }

  private waitForTokenRefresh(): Observable<boolean> {
    return this.isRefreshInProgressSubject.asObservable().pipe(
      filter(value => value === false),
      take(1),
    );
  }

  handleAuthError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
    if (!isRefreshTokenUrl(req.url) && !isLogoutUrl(req.url)) { // Refresh token and retry
      if (this.isRefreshInProgressSubject.value === true) {
        return this.waitForTokenRefresh().pipe(
          switchMap(() => next.handle(this.addTokenToRequest(req))));
      }

      this.isRefreshInProgressSubject.next(true);
      return this.authService.refreshToken().pipe(
        finalize(() => {
          this.isRefreshInProgressSubject.next(false);
        }),
        switchMap(() => next.handle(this.addTokenToRequest(req)))
      );
    }

    if (isRefreshTokenUrl(req.url)) {
      this.authService.logout();
    }
  }

  private addTokenToRequest(req: HttpRequest<any>): HttpRequest<any> {
    const isAuthDomain = req.url.startsWith(environment.api);
    if (!isAuthDomain) {
      return req;
    }
    const token = isRefreshTokenUrl(req.url) || isLogoutUrl(req.url) ?
      this.authService.getRefreshToken() :
      this.authService.getAccessToken();
    return req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
  }
}
