/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateAffiliateCustomerWebsiteDto } from '../models/create-affiliate-customer-website-dto';
import { CreateAffiliateSubscriptionDto } from '../models/create-affiliate-subscription-dto';
import { PodcastEpisodeDto } from '../models/podcast-episode-dto';
import { SubscriptionStatus } from '../models/subscription-status';
import { UpdateAffiliateRequestDto } from '../models/update-affiliate-request-dto';

@Injectable({ providedIn: 'root' })
export class AffiliateApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `affiliateControllerUpdateAffiliate()` */
  static readonly AffiliateControllerUpdateAffiliatePath = '/api/affiliate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerUpdateAffiliate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerUpdateAffiliate$Response(
    params: {
      body: UpdateAffiliateRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerUpdateAffiliatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerUpdateAffiliate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerUpdateAffiliate(
    params: {
      body: UpdateAffiliateRequestDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.affiliateControllerUpdateAffiliate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `affiliateControllerListApiKeys()` */
  static readonly AffiliateControllerListApiKeysPath = '/api/affiliate/api-key';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerListApiKeys()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerListApiKeys$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerListApiKeysPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerListApiKeys$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerListApiKeys(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.affiliateControllerListApiKeys$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `affiliateControllerCreateApiKey()` */
  static readonly AffiliateControllerCreateApiKeyPath = '/api/affiliate/api-key';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerCreateApiKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerCreateApiKey$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerCreateApiKeyPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerCreateApiKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerCreateApiKey(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.affiliateControllerCreateApiKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `affiliateControllerDeleteApiKey()` */
  static readonly AffiliateControllerDeleteApiKeyPath = '/api/affiliate/api-key/{keyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerDeleteApiKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerDeleteApiKey$Response(
    params: {
      keyId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerDeleteApiKeyPath, 'delete');
    if (params) {
      rb.path('keyId', params.keyId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerDeleteApiKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerDeleteApiKey(
    params: {
      keyId: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.affiliateControllerDeleteApiKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `affiliateControllerListSubscriptions()` */
  static readonly AffiliateControllerListSubscriptionsPath = '/api/affiliate/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerListSubscriptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerListSubscriptions$Response(
    params?: {
      status?: SubscriptionStatus;
      deleted?: true;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerListSubscriptionsPath, 'get');
    if (params) {
      rb.query('status', params.status, {});
      rb.query('deleted', params.deleted, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerListSubscriptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerListSubscriptions(
    params?: {
      status?: SubscriptionStatus;
      deleted?: true;
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.affiliateControllerListSubscriptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `affiliateControllerCreateSubscription()` */
  static readonly AffiliateControllerCreateSubscriptionPath = '/api/affiliate/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerCreateSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerCreateSubscription$Response(
    params: {
      body: CreateAffiliateSubscriptionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerCreateSubscriptionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerCreateSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerCreateSubscription(
    params: {
      body: CreateAffiliateSubscriptionDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.affiliateControllerCreateSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `affiliateControllerDeleteSubscription()` */
  static readonly AffiliateControllerDeleteSubscriptionPath = '/api/affiliate/subscription/{subscriptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerDeleteSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerDeleteSubscription$Response(
    params: {
      subscriptionId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerDeleteSubscriptionPath, 'delete');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerDeleteSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerDeleteSubscription(
    params: {
      subscriptionId: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.affiliateControllerDeleteSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `affiliateControllerListWebsites()` */
  static readonly AffiliateControllerListWebsitesPath = '/api/affiliate/website';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerListWebsites()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerListWebsites$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<{
}>>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerListWebsitesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerListWebsites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerListWebsites(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<{
}>> {
    return this.affiliateControllerListWebsites$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{
}>>): Array<{
}> => r.body)
    );
  }

  /** Path part for operation `affiliateControllerCreateWebsite()` */
  static readonly AffiliateControllerCreateWebsitePath = '/api/affiliate/website';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerCreateWebsite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerCreateWebsite$Response(
    params: {
      body: CreateAffiliateCustomerWebsiteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerCreateWebsitePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerCreateWebsite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  affiliateControllerCreateWebsite(
    params: {
      body: CreateAffiliateCustomerWebsiteDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.affiliateControllerCreateWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `affiliateControllerDeleteWebsite()` */
  static readonly AffiliateControllerDeleteWebsitePath = '/api/affiliate/website/{websiteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerDeleteWebsite()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerDeleteWebsite$Response(
    params: {
      websiteId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerDeleteWebsitePath, 'delete');
    if (params) {
      rb.path('websiteId', params.websiteId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerDeleteWebsite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerDeleteWebsite(
    params: {
      websiteId: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.affiliateControllerDeleteWebsite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `affiliateControllerGetAffiliateEpisodes()` */
  static readonly AffiliateControllerGetAffiliateEpisodesPath = '/api/affiliate/episodes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affiliateControllerGetAffiliateEpisodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerGetAffiliateEpisodes$Response(
    params: {
      podcastType: 'PODCAST' | 'VIDEO_CHANNEL';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PodcastEpisodeDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AffiliateApi.AffiliateControllerGetAffiliateEpisodesPath, 'get');
    if (params) {
      rb.query('podcastType', params.podcastType, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PodcastEpisodeDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affiliateControllerGetAffiliateEpisodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  affiliateControllerGetAffiliateEpisodes(
    params: {
      podcastType: 'PODCAST' | 'VIDEO_CHANNEL';
    },
    context?: HttpContext
  ): Observable<Array<PodcastEpisodeDto>> {
    return this.affiliateControllerGetAffiliateEpisodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PodcastEpisodeDto>>): Array<PodcastEpisodeDto> => r.body)
    );
  }

}
