// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'staging',
  production: true,
  api: 'https://api.podcastpage.xyz',
  paddleSuccessRoute: '/processing',
  paddleErrorRoute: '/signup/error',
  paddleVendorId: 111817,
  proxyServerAddress: '137.66.43.108',
  assetsUrl: 'https://assets.podcastpage.xyz',
  defaultThemesJsonUrl: 'https://assets.podcastpage.io/demo/themes/default_themes.json',
  rootDomain: 'podcastpage.xyz',
  dashboardUrl: 'https://app.podcastpage.xyz',
  headlinerApiKey: 'KFWdNER4f9V7nDxEECpwULn5u',
  googleClientId: '805134116816-kk9e5p6ngsuv58sof9o4sj3se32rfbf6.apps.googleusercontent.com',
  instagramAppId: '603216910895778',
  referralSsoUrl: 'https://podcastpage.firstpromoter.com',
  stripeClientId: 'ca_NKHxlIy7v00N1z1lOmo6nX9PEkLqKK7M',
  isPaddleSandbox: false,
  featureFlags: {
    stripe: true,
    memberships: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
